(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/tipsen-link/assets/javascripts/tipsen-link.js') >= 0) return;  svs.modules.push('/components/tipsen/tipsen-link/assets/javascripts/tipsen-link.js');
"use strict";

const _excluded = ["disabled", "to", "children", "Component"];
function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var s = Object.getOwnPropertySymbols(e); for (r = 0; r < s.length; r++) o = s[r], t.includes(o) || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.includes(n)) continue; t[n] = r[n]; } return t; }
const {
  Link,
  useLocation
} = ReactRouterDOM;
const {
  useAppendedPersistedSearchParams
} = svs.components.tipsen.tipsen_link;
const useUrlWithSearchParams = to => {
  let linkTo = {};
  const location = useLocation();
  if (typeof to === 'function') {
    to = to(location);
  }
  let providedSearchParamsString;
  if (typeof to === 'object') {
    linkTo = to;
    if (to.search) {
      providedSearchParamsString = to.search;
    }
  } else if (typeof to === 'string') {
    const [toWithoutHash, hash] = to.split('#');
    linkTo.hash = hash;
    if (toWithoutHash.includes('?')) {
      const [pathname, search] = toWithoutHash.split('?');
      providedSearchParamsString = search;
      linkTo.pathname = pathname;
    } else {
      linkTo.pathname = toWithoutHash;
    }
  }
  const finalSearchParams = useAppendedPersistedSearchParams(providedSearchParamsString);
  if (finalSearchParams.toString().length > 0) {
    linkTo.search = "?".concat(finalSearchParams.toString());
  }
  return linkTo;
};
const TipsenLink = _ref => {
  let {
      disabled,
      to,
      children,
      Component = Link
    } = _ref,
    props = _objectWithoutProperties(_ref, _excluded);
  const linkTo = useUrlWithSearchParams(to);
  if (disabled) {
    return React.createElement("div", props, children);
  }
  const toProp = {};
  if (Component === 'a') {
    toProp.href = linkTo;
  } else {
    toProp.to = linkTo;
  }
  return (
    React.createElement(Component, _extends({}, toProp, props), children)
  );
};
setGlobal('svs.components.tipsen.tipsen_link.TipsenLink', TipsenLink);

 })(window);