(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/tipsen-link/assets/javascripts/use-appended-persisted-search-params.js') >= 0) return;  svs.modules.push('/components/tipsen/tipsen-link/assets/javascripts/use-appended-persisted-search-params.js');
"use strict";

const {
  useLocation
} = ReactRouterDOM;
const {
  getAppendedPersistedSearchParams
} = svs.components.sport.tipsenShared;

const useAppendedPersistedSearchParams = (searchParams, persistedKeys) => {
  const location = useLocation();
  return getAppendedPersistedSearchParams(location, searchParams, persistedKeys);
};
setGlobal('svs.components.tipsen.tipsen_link.useAppendedPersistedSearchParams', useAppendedPersistedSearchParams);

 })(window);