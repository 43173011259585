(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/tipsen-link/assets/javascripts/use-persisted-search-params.js') >= 0) return;  svs.modules.push('/components/tipsen/tipsen-link/assets/javascripts/use-persisted-search-params.js');
"use strict";

const {
  useLocation
} = ReactRouterDOM;
const {
  getPersistedSearchParams
} = svs.components.sport.tipsenShared;

const usePersistedSearchParams = persistedQueryParameters => {
  const location = useLocation();
  const existingSearchParams = new URLSearchParams(location.search);
  return getPersistedSearchParams(existingSearchParams, persistedQueryParameters);
};
setGlobal('svs.components.tipsen.tipsen_link.usePersistedSearchParams', usePersistedSearchParams);

 })(window);