(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/tipsen-link/assets/javascripts/utils.js') >= 0) return;  svs.modules.push('/components/tipsen/tipsen-link/assets/javascripts/utils.js');
"use strict";


const {
  SportinfoPathNames
} = svs.components.sportinfo.common.constants;
const {
  baseUrl
} = svs.core.data;
const {
  TipsenModuleGameUrl
} = svs.components.sport.tipsenShared;
const validBigStatPaths = [TipsenModuleGameUrl.STANDARD, TipsenModuleGameUrl.SYSTEM, TipsenModuleGameUrl.TIPS_SM, TipsenModuleGameUrl.TIPS_EM];
const validBigStatPathNames = Object.values(SportinfoPathNames);
const getEventLink = () => {
  const relativeUrl = location.pathname.slice(baseUrl.length).slice(1);
  const [first, second] = relativeUrl.split('/');
  let href = location.pathname.slice(baseUrl.length);
  if (!validBigStatPaths.includes(first) && !validBigStatPathNames.includes(first) || validBigStatPaths.includes(first) && !validBigStatPathNames.includes(second)) {
    if (validBigStatPaths.includes(first)) {
      href = "/".concat(first);
    } else {
      href = "/".concat(TipsenModuleGameUrl.STANDARD);
    }
  }
  return href;
};
setGlobal('svs.components.tipsen.tipsen_link.utils', {
  getEventLink
});

 })(window);